.container {
  min-width: 375px;
  .task_container {
    margin: 16px;
    padding: 16px;
    border: 1px solid black;
    border-radius: 8px;
    .task_row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      h3 {
        margin: 0;
      }
    }
  }
}
