.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    font-size: 60px;
    color: white;
  }
}
